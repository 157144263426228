import HTTP from './default';
import coursesResponse from './mockups/questionnaireCourses.json';
import profileCoursesResponse from '../api/mockups/profileCourses.json';
import questionnairesResponse from '../api/mockups/questionnaires.json';

export default {
    login(data, token) {
        return HTTP({
            url: '/auth/oauth2/login/',
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`,
            },
            data,
        });
    },

    getAllCourses({page = 1, search = null, ordering = null, tag = null, themes = null, }) {
        return HTTP({
            url: '/courses/',
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
            params: {
                search,
                page,
                ordering,
                tag,
                themes,
            },
        });
    },

    getTags() {
        return HTTP({
            url: '/courses/tag_list/',
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },

    getThemes() {
        return HTTP({
            url: '/courses/theme_list/',
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },

    getCourse(id) {
        return HTTP({
            url: `courses/${id}/`,
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },

    getUserCourse(id) {
        return HTTP({
            url: `user/courses/${id}/`,
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },

    getProgram(id) {
        return HTTP({
            url: `user/programs/${id}/`,
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },

    registerOnCourse(courseId, sessionId) {
        return HTTP({
            url: `/courses/${courseId}/register/`,
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
            data: {
                session_id: sessionId,
            },
        });
    },

    getQuestionnaireCourses() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(coursesResponse);
            }, 1000);
        });
    },

    getProfileCourses() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(profileCoursesResponse);
            }, 1000);
        });
    },

    getQuestionnaires() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(questionnairesResponse);
            }, 1000);
        });
    },

    getShowcaseCourses(theme) {
        return HTTP({
            url: '/courses/',
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
            params: {
                themes: theme,
            },
        });
    },
};
